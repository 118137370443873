import React, { memo } from 'react';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { routes } from 'utils/routing';
import { EventList } from 'api/general/models';

import Fab from 'components/common/Fab';
import Image from 'components/common/Image';
import EventCard from './EventCard';
import LoggedOutFavoritePopUp from './LoggedOutFavoritePopUp';

interface IProps {
  event: EventList;
  isFavoriteOfflineToggled?: boolean;
  onLogin: () => void;
  onToggleFavorite: (eventId: number) => void;
  onCloseFavoritePopUp: () => void;
  showLocation?: boolean;
  dateFirst?: boolean;
  noLocationLiveIcons?: boolean;
  padding?: number;
}

const UserEventCard: React.FC<IProps> = (props) => {
  const {
    event,
    isFavoriteOfflineToggled,
    onLogin,
    onToggleFavorite,
    onCloseFavoritePopUp,
    showLocation,
    dateFirst,
    noLocationLiveIcons,
    padding,
  } = props;
  const {
    id,
    title,
    organization,
    start_at,
    end_at,
    timezone,
    main_image,
    categories,
    target_ages,
    min_ticket_price,
    max_ticket_price,
    has_location,
    is_virtual,
    user_favorite,
    default_currency,
    hide_date_time,
    ...other
  } = event;

  const FabIcon = user_favorite ? FavoriteIcon : FavoriteBorderIcon;

  const handleFabClick = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    e.stopPropagation();
    onToggleFavorite(event.id);
  };

  return (
    <EventCard
      {...other}
      id={id}
      title={title}
      organizationId={organization.id}
      organizationTitle={organization.title}
      organizationLogo={organization.logo}
      startAt={start_at}
      endAt={end_at}
      timeZone={timezone}
      imageUrl={main_image}
      categories={categories}
      targetAges={target_ages}
      currency={default_currency}
      minTicketPrice={min_ticket_price}
      maxTicketPrice={max_ticket_price}
      hasLocation={showLocation && has_location}
      isVirtual={is_virtual}
      eventRoute={routes.event(id)}
      isPopUpToggled={isFavoriteOfflineToggled}
      organizationRoute={routes.organizationProfile(organization.id)}
      dateFirst={dateFirst}
      hideDate={hide_date_time}
      noLocationLiveIcons={noLocationLiveIcons}
      padding={padding}
      imagePopUp={
        <Image
          variant="rectangle"
          rounded
          alt={title}
          placeholder={<LoggedOutFavoritePopUp onClose={onCloseFavoritePopUp} onLogin={onLogin} />}
        />
      }
      fab={
        <Fab elevation={1} onClick={handleFabClick}>
          <FabIcon color="secondary" />
        </Fab>
      }
    />
  );
};

export default memo(UserEventCard);
