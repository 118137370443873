import React from 'react';
import { Container } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import EventsSectionContainer from './EventsSectionContainer';
import { shuffledHomepageSections } from './DynamicHomePageSections';

interface IProps {
  market: string;
  itemsPerPage: number;
  contentLoadingState?: (context: string, isLoaded: boolean, hasItems: boolean) => void;
}

const useStyles = makeStyles()(() => ({
  root: {
    '&&&': {
      padding: '0',
    },
  },
}));

const DynamicSectionsContainer: React.FC<IProps> = ({ market, itemsPerPage, contentLoadingState }) => {
  const { classes } = useStyles();
  return (
    <Container maxWidth="lg" className={classes.root}>
      {shuffledHomepageSections().map((section) => (
        <EventsSectionContainer
          key={section.titleKey}
          titleKey={section.titleKey}
          market={market}
          ordering={section.ordering}
          itemsPerPage={itemsPerPage}
          additionalFilters={section.filter}
          context={section.context}
          contentLoadingState={contentLoadingState}
        />
      ))}
    </Container>
  );
};

export default DynamicSectionsContainer;
