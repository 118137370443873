import React, { memo } from 'react';
import { Grid, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useTranslation } from 'i18n';
import useAuthProps from 'hooks/useAuthProps';
import useLoggedOutFavoriteEvent from 'hooks/useLoggedOutFavoriteEvent';
import UserEventCard from 'components/common/EventCard/UserEventCard';
import { useRouter } from 'next/router';
import Button from './Button';

interface IProps {
  title: string;
  events: any[];
  itemsPerPage: number;
  href: any;
  onToggleFavoriteEvent: (id: number) => void;
}

const useStyles = makeStyles()((theme) => ({
  scrollContainer: {
    [theme.breakpoints.down('md')]: {
      overflowX: 'auto',
      ...theme.mixins.scrollBarOverflowYAuto,
    },
  },
  itemContainer: {
    [theme.breakpoints.down('md')]: {
      minWidth: '100vw',
    },
  },
}));

const EventsSection: React.FC<IProps> = (props) => {
  const { title, events, itemsPerPage, href, onToggleFavoriteEvent } = props;
  const { classes } = useStyles();
  const router = useRouter();
  const [t] = useTranslation('public');

  const { isAuthenticated, onLogin } = useAuthProps();
  const { loggedOutFavoriteEvent, setLoggedOutFavoriteEvent, handleResetLoggedOutFavoriteEvent } =
    useLoggedOutFavoriteEvent();

  const hasMoreImages = events.length - itemsPerPage;

  return (
    <Grid container>
      <Grid item xs={12} sx={{ pb: 3 }}>
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          <Grid item xs>
            <Typography variant="h4">{title}</Typography>
          </Grid>

          {hasMoreImages > 0 && (
            <Grid item>
              <Grid container alignItems="center">
                <Grid item>
                  <Button rounded color="primary" size="small" variant="outlined" onClick={() => router.push(href)}>
                    {t('common:button.viewAll')}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={6} sx={{ pt: 3, pb: 3 }} className={classes.scrollContainer}>
          {events.slice(0, itemsPerPage).map((event) => (
            <Grid className={classes.itemContainer} item md={4} key={event.id}>
              <UserEventCard
                event={event}
                isFavoriteOfflineToggled={loggedOutFavoriteEvent === event.id}
                onLogin={onLogin}
                onToggleFavorite={isAuthenticated ? onToggleFavoriteEvent : setLoggedOutFavoriteEvent}
                onCloseFavoritePopUp={handleResetLoggedOutFavoriteEvent}
                showLocation
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default memo(EventsSection);
