import React, { useEffect, useState } from 'react';
import { Typography, TypographyProps } from '@mui/material';

interface TextTransformerProps extends TypographyProps {
  limit?: number;
  children?: string;
  component?: string;
  defaultFontSize?: any;
  minFontSize?: any;
  midFontSize?: any;
  transformAfter?: number;
  extraTransformAfter?: number;
  transform?: boolean;
}

const TextTransformer: React.FC<TextTransformerProps> = ({
  limit,
  defaultFontSize,
  minFontSize,
  midFontSize = minFontSize,
  transformAfter,
  extraTransformAfter = transformAfter,
  children,
  transform = true,
  ...other
}) => {
  const [fontSize, setFontSize] = useState(defaultFontSize);

  // eslint-disable-next-line no-unsafe-optional-chaining
  const fillPercentage = Math.ceil((children?.length / limit) * 100);

  useEffect(() => {
    if (transform) {
      switch (true) {
        case fillPercentage <= transformAfter:
          setFontSize(defaultFontSize);
          break;
        case fillPercentage <= extraTransformAfter:
          setFontSize(midFontSize);
          break;
        case fillPercentage > extraTransformAfter:
          setFontSize(minFontSize);
          break;
        default:
          break;
      }
    }
  }, [fillPercentage, defaultFontSize, midFontSize, minFontSize, transformAfter, extraTransformAfter, transform]);

  return (
    <Typography variant={fontSize} {...other}>
      {children}
    </Typography>
  );
};

export default TextTransformer;
