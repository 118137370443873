import React from 'react';
import { Grid } from '@mui/material';

import EventPromoCard from './EventPromoCard';

export type EventPromoCardType = {
  title: string;
  backgroundImage: string;
  objectPosition?: string;
  backgroundColor: string;
  route: {
    href: any;
    link: string;
  };
};

interface IProps {
  eventsPromoCards: any[];
}

const EventPromoCardsContainer: React.FC<IProps> = (props) => {
  const { eventsPromoCards } = props;
  const isLargeCard = eventsPromoCards.length < 4;

  return (
    <Grid container spacing={4}>
      {eventsPromoCards.map((card: EventPromoCardType) => (
        <Grid item xs={12} md={isLargeCard ? 4 : 3} key={card.title}>
          <EventPromoCard {...{ ...card, ...{ isLargeCard } }} />
        </Grid>
      ))}
    </Grid>
  );
};

export default EventPromoCardsContainer;
